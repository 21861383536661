<template>
  <div class="brochuresItem2">

    <div class="nav-section">

      <h1>Лінійка бренду Тева</h1>

      <a download href="/brochuresItem2.pdf" class="nav-section__download">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12 15.575C11.8667 15.575 11.7417 15.5543 11.625 15.513C11.5083 15.4717 11.4 15.4007 11.3 15.3L7.7 11.7C7.51667 11.5167 7.425 11.2833 7.425 11C7.425 10.7167 7.51667 10.4833 7.7 10.3C7.88333 10.1167 8.121 10.021 8.413 10.013C8.705 10.005 8.94234 10.0923 9.125 10.275L11 12.15V5C11 4.71667 11.096 4.479 11.288 4.287C11.48 4.095 11.7173 3.99934 12 4C12.2833 4 12.521 4.096 12.713 4.288C12.905 4.48 13.0007 4.71734 13 5V12.15L14.875 10.275C15.0583 10.0917 15.296 10.004 15.588 10.012C15.88 10.02 16.1173 10.116 16.3 10.3C16.4833 10.4833 16.575 10.7167 16.575 11C16.575 11.2833 16.4833 11.5167 16.3 11.7L12.7 15.3C12.6 15.4 12.4917 15.471 12.375 15.513C12.2583 15.555 12.1333 15.5757 12 15.575ZM6 20C5.45 20 4.979 19.804 4.587 19.412C4.195 19.02 3.99934 18.5493 4 18V16C4 15.7167 4.096 15.479 4.288 15.287C4.48 15.095 4.71733 14.9993 5 15C5.28333 15 5.521 15.096 5.713 15.288C5.905 15.48 6.00067 15.7173 6 16V18H18V16C18 15.7167 18.096 15.479 18.288 15.287C18.48 15.095 18.7173 14.9993 19 15C19.2833 15 19.521 15.096 19.713 15.288C19.905 15.48 20.0007 15.7173 20 16V18C20 18.55 19.804 19.021 19.412 19.413C19.02 19.805 18.5493 20.0007 18 20H6Z"
              fill="#35786E"/>
        </svg>
        Завантажити pdf
      </a>
    </div>

    <div class="content">

      <div class="green-block">

        <svg class="circle circle1" width="284" height="284" viewBox="0 0 284 284" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <mask id="mask0_2166_18178" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="284"
                  height="284">
              <circle cx="142" cy="142" r="142" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2166_18178)">
              <path d="M-43.1995 1071.3L962.38 278.741" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2219 1065.38L962.358 272.816" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2445 1059.45L962.335 266.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.267 1053.53L962.324 260.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2896 1047.6L962.302 255.042" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3008 1041.69L962.279 249.117" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3234 1035.76L962.256 243.202" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3458 1029.84L962.234 237.277" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3684 1023.91L962.211 231.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.391 1017.99L962.2 225.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4135 1012.06L962.178 219.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4248 1006.14L962.155 213.578" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4473 1000.21L962.133 207.653" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4698 994.288L962.11 201.729" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4923 988.373L962.087 195.813" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5149 982.448L962.076 189.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5375 976.523L962.054 183.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5599 970.599L962.031 178.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5712 964.673L962.009 172.114" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5938 958.749L961.986 166.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6163 952.824L961.963 160.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6388 946.9L961.952 154.34" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6614 940.984L961.93 148.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6838 935.06L961.907 142.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6952 929.135L961.885 136.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7177 923.21L961.862 130.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7402 917.285L961.839 124.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7627 911.36L961.828 118.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7853 905.436L961.806 112.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8079 899.511L961.783 106.952" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8191 893.586L961.761 101.027" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8417 887.671L961.738 95.1118" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8641 881.746L961.716 89.187" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8867 875.821L961.693 83.2622" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9092 869.897L961.682 77.3374" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9318 863.972L961.659 71.4126" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.943 858.047L961.637 65.4878" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9656 852.122L961.614 59.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.988 846.197L961.592 53.6382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0106 840.283L961.569 47.7236" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0332 834.357L961.558 41.7983" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0557 828.433L961.535 35.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.067 822.508L961.513 29.9492" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0895 816.583L961.49 24.0244" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1121 810.658L961.468 18.0991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1345 804.734L961.445 12.1748" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1571 798.809L961.434 6.25" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1796 792.884L961.411 0.325195" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1909 786.969L961.389 -5.58984" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2134 781.044L961.366 -11.5146" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.236 775.12L961.344 -17.4395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2585 769.195L961.321 -23.3643" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.281 763.27L961.31 -29.2891" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3036 757.345L961.287 -35.2139" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3148 751.42L961.265 -41.1387" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3374 745.496L961.242 -47.0635" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3599 739.581L961.22 -52.9785" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3824 733.656L961.197 -58.9033" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4049 727.731L961.186 -64.8281" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4275 721.806L961.164 -70.7529" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4387 715.881L961.141 -76.6777" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4613 709.957L961.118 -82.6025" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4839 704.032L961.096 -88.5273" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5063 698.107L961.073 -94.4521" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5289 692.192L961.062 -100.377" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5514 686.267L961.04 -106.292" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5627 680.342L961.017 -112.217" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5852 674.418L960.995 -118.142" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6078 668.493L960.972 -124.066" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6302 662.568L960.95 -129.991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6528 656.643L960.938 -135.916" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6754 650.718L960.916 -141.841" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6979 644.793L960.893 -147.766" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7091 638.878L960.871 -153.681" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7317 632.954L960.848 -159.605" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7543 627.029L960.826 -165.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7767 621.104L960.814 -171.455" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7993 615.179L960.792 -177.38" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8218 609.254L960.769 -183.305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8331 603.33L960.747 -189.229" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8556 597.405L960.724 -195.154" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8782 591.49L960.702 -201.069" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9008 585.565L960.69 -206.994" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9232 579.641L960.668 -212.918" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9458 573.716L960.645 -218.843" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.957 567.791L960.623 -224.768" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9796 561.866L960.6 -230.693" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0018 555.941L960.578 -236.618" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0244 550.017L960.555 -242.542" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0471 544.092L960.544 -248.467" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0697 538.177L960.521 -254.382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0809 532.252L960.499 -260.307" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1035 526.327L960.476 -266.232" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.126 520.402L960.454 -272.157" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1486 514.478L960.431 -278.082" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1711 508.553L960.42 -284.006" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1936 502.628L960.397 -289.931" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.205 496.703L960.375 -295.856" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2274 490.788L960.352 -301.771" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.25 484.863L960.33 -307.696" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2725 478.938L960.307 -313.621" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.295 473.014L960.296 -319.545" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3176 467.089L960.273 -325.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3289 461.164L960.251 -331.395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3513 455.239L960.228 -337.32" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3739 449.315L960.206 -343.245" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3965 443.399L960.183 -349.169" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4189 437.475L960.172 -355.084" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4415 431.55L960.149 -361.009" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4528 425.625L960.127 -366.934" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4753 419.7L960.104 -372.859" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4978 413.776L960.082 -378.783" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5204 407.851L960.059 -384.708" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5428 401.926L960.048 -390.633" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5654 396.001L960.026 -396.558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5767 390.086L960.003 -402.473" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5992 384.162L959.981 -408.397" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6218 378.237L959.958 -414.322" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6443 372.312L959.935 -420.247" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6669 366.387L959.924 -426.172" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6893 360.462L959.902 -432.097" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7007 354.538L959.879 -438.021" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7231 348.613L959.857 -443.946" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7457 342.698L959.834 -449.861" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7682 336.773L959.812 -455.786" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7908 330.848L959.8 -461.711" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8134 324.923L959.778 -467.636" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8358 318.999L959.755 -473.561" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.847 313.074L959.733 -479.485" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8696 307.149L959.71 -485.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8922 301.224L959.688 -491.335" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9147 295.299L959.676 -497.26" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9373 289.384L959.654 -503.175" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9597 283.459L959.631 -509.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9711 277.535L959.609 -515.024" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9935 271.61L959.586 -520.949" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0161 265.685L959.564 -526.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0387 259.76L959.552 -532.799" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0612 253.835L959.53 -538.724" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0837 247.911L959.507 -544.648" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.095 241.996L959.485 -550.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1176 236.071L959.462 -556.488" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.14 230.146L959.44 -562.413" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1626 224.221L959.417 -568.338" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1851 218.296L959.406 -574.263" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2076 212.372L959.383 -580.188" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2189 206.447L959.361 -586.112" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2415 200.522L959.338 -592.037" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.264 194.597L959.316 -597.962" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2865 188.683L959.293 -603.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3091 182.758L959.282 -609.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3315 176.833L959.26 -615.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3429 170.908L959.237 -621.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3654 164.984L959.214 -627.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3879 159.059L959.192 -633.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4104 153.134L959.169 -639.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.433 147.209L959.158 -645.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4556 141.294L959.136 -651.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4668 135.369L959.113 -657.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4893 129.444L959.09 -663.115" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5118 123.52L959.068 -669.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5344 117.595L959.045 -674.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5569 111.67L959.034 -680.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5795 105.745L959.012 -686.814" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5907 99.8203L958.989 -692.739" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6133 93.9053L958.967 -698.664" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6357 87.9806L958.944 -704.579" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6583 82.0557L958.921 -710.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6808 76.1308L958.91 -716.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7034 70.2062L958.888 -722.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7146 64.2813L958.865 -728.278" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7372 58.3564L958.843 -734.203" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7598 52.4316L958.82 -740.127" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7822 46.5069L958.797 -746.052" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8048 40.5918L958.786 -751.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8273 34.667L958.764 -757.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8386 28.7422L958.741 -763.817" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8611 22.8174L958.719 -769.742" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8837 16.8926L958.696 -775.667" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9062 10.9683L958.673 -781.591" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9287 5.04345L958.662 -787.516" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
            </g>
          </g>
        </svg>

        <svg class="circle circle2" width="284" height="284" viewBox="0 0 284 284" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <mask id="mask0_2166_18178" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="284"
                  height="284">
              <circle cx="142" cy="142" r="142" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2166_18178)">
              <path d="M-43.1995 1071.3L962.38 278.741" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2219 1065.38L962.358 272.816" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2445 1059.45L962.335 266.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.267 1053.53L962.324 260.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2896 1047.6L962.302 255.042" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3008 1041.69L962.279 249.117" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3234 1035.76L962.256 243.202" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3458 1029.84L962.234 237.277" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3684 1023.91L962.211 231.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.391 1017.99L962.2 225.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4135 1012.06L962.178 219.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4248 1006.14L962.155 213.578" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4473 1000.21L962.133 207.653" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4698 994.288L962.11 201.729" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4923 988.373L962.087 195.813" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5149 982.448L962.076 189.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5375 976.523L962.054 183.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5599 970.599L962.031 178.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5712 964.673L962.009 172.114" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5938 958.749L961.986 166.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6163 952.824L961.963 160.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6388 946.9L961.952 154.34" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6614 940.984L961.93 148.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6838 935.06L961.907 142.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6952 929.135L961.885 136.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7177 923.21L961.862 130.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7402 917.285L961.839 124.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7627 911.36L961.828 118.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7853 905.436L961.806 112.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8079 899.511L961.783 106.952" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8191 893.586L961.761 101.027" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8417 887.671L961.738 95.1118" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8641 881.746L961.716 89.187" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8867 875.821L961.693 83.2622" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9092 869.897L961.682 77.3374" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9318 863.972L961.659 71.4126" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.943 858.047L961.637 65.4878" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9656 852.122L961.614 59.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.988 846.197L961.592 53.6382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0106 840.283L961.569 47.7236" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0332 834.357L961.558 41.7983" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0557 828.433L961.535 35.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.067 822.508L961.513 29.9492" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0895 816.583L961.49 24.0244" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1121 810.658L961.468 18.0991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1345 804.734L961.445 12.1748" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1571 798.809L961.434 6.25" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1796 792.884L961.411 0.325195" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1909 786.969L961.389 -5.58984" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2134 781.044L961.366 -11.5146" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.236 775.12L961.344 -17.4395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2585 769.195L961.321 -23.3643" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.281 763.27L961.31 -29.2891" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3036 757.345L961.287 -35.2139" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3148 751.42L961.265 -41.1387" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3374 745.496L961.242 -47.0635" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3599 739.581L961.22 -52.9785" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3824 733.656L961.197 -58.9033" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4049 727.731L961.186 -64.8281" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4275 721.806L961.164 -70.7529" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4387 715.881L961.141 -76.6777" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4613 709.957L961.118 -82.6025" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4839 704.032L961.096 -88.5273" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5063 698.107L961.073 -94.4521" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5289 692.192L961.062 -100.377" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5514 686.267L961.04 -106.292" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5627 680.342L961.017 -112.217" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5852 674.418L960.995 -118.142" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6078 668.493L960.972 -124.066" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6302 662.568L960.95 -129.991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6528 656.643L960.938 -135.916" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6754 650.718L960.916 -141.841" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6979 644.793L960.893 -147.766" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7091 638.878L960.871 -153.681" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7317 632.954L960.848 -159.605" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7543 627.029L960.826 -165.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7767 621.104L960.814 -171.455" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7993 615.179L960.792 -177.38" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8218 609.254L960.769 -183.305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8331 603.33L960.747 -189.229" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8556 597.405L960.724 -195.154" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8782 591.49L960.702 -201.069" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9008 585.565L960.69 -206.994" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9232 579.641L960.668 -212.918" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9458 573.716L960.645 -218.843" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.957 567.791L960.623 -224.768" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9796 561.866L960.6 -230.693" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0018 555.941L960.578 -236.618" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0244 550.017L960.555 -242.542" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0471 544.092L960.544 -248.467" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0697 538.177L960.521 -254.382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0809 532.252L960.499 -260.307" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1035 526.327L960.476 -266.232" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.126 520.402L960.454 -272.157" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1486 514.478L960.431 -278.082" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1711 508.553L960.42 -284.006" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1936 502.628L960.397 -289.931" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.205 496.703L960.375 -295.856" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2274 490.788L960.352 -301.771" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.25 484.863L960.33 -307.696" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2725 478.938L960.307 -313.621" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.295 473.014L960.296 -319.545" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3176 467.089L960.273 -325.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3289 461.164L960.251 -331.395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3513 455.239L960.228 -337.32" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3739 449.315L960.206 -343.245" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3965 443.399L960.183 -349.169" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4189 437.475L960.172 -355.084" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4415 431.55L960.149 -361.009" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4528 425.625L960.127 -366.934" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4753 419.7L960.104 -372.859" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4978 413.776L960.082 -378.783" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5204 407.851L960.059 -384.708" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5428 401.926L960.048 -390.633" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5654 396.001L960.026 -396.558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5767 390.086L960.003 -402.473" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5992 384.162L959.981 -408.397" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6218 378.237L959.958 -414.322" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6443 372.312L959.935 -420.247" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6669 366.387L959.924 -426.172" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6893 360.462L959.902 -432.097" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7007 354.538L959.879 -438.021" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7231 348.613L959.857 -443.946" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7457 342.698L959.834 -449.861" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7682 336.773L959.812 -455.786" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7908 330.848L959.8 -461.711" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8134 324.923L959.778 -467.636" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8358 318.999L959.755 -473.561" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.847 313.074L959.733 -479.485" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8696 307.149L959.71 -485.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8922 301.224L959.688 -491.335" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9147 295.299L959.676 -497.26" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9373 289.384L959.654 -503.175" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9597 283.459L959.631 -509.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9711 277.535L959.609 -515.024" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9935 271.61L959.586 -520.949" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0161 265.685L959.564 -526.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0387 259.76L959.552 -532.799" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0612 253.835L959.53 -538.724" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0837 247.911L959.507 -544.648" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.095 241.996L959.485 -550.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1176 236.071L959.462 -556.488" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.14 230.146L959.44 -562.413" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1626 224.221L959.417 -568.338" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1851 218.296L959.406 -574.263" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2076 212.372L959.383 -580.188" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2189 206.447L959.361 -586.112" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2415 200.522L959.338 -592.037" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.264 194.597L959.316 -597.962" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2865 188.683L959.293 -603.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3091 182.758L959.282 -609.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3315 176.833L959.26 -615.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3429 170.908L959.237 -621.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3654 164.984L959.214 -627.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3879 159.059L959.192 -633.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4104 153.134L959.169 -639.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.433 147.209L959.158 -645.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4556 141.294L959.136 -651.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4668 135.369L959.113 -657.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4893 129.444L959.09 -663.115" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5118 123.52L959.068 -669.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5344 117.595L959.045 -674.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5569 111.67L959.034 -680.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5795 105.745L959.012 -686.814" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5907 99.8203L958.989 -692.739" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6133 93.9053L958.967 -698.664" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6357 87.9806L958.944 -704.579" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6583 82.0557L958.921 -710.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6808 76.1308L958.91 -716.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7034 70.2062L958.888 -722.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7146 64.2813L958.865 -728.278" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7372 58.3564L958.843 -734.203" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7598 52.4316L958.82 -740.127" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7822 46.5069L958.797 -746.052" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8048 40.5918L958.786 -751.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8273 34.667L958.764 -757.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8386 28.7422L958.741 -763.817" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8611 22.8174L958.719 -769.742" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8837 16.8926L958.696 -775.667" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9062 10.9683L958.673 -781.591" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9287 5.04345L958.662 -787.516" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
            </g>
          </g>
        </svg>

        <svg class="circle circle3" width="284" height="284" viewBox="0 0 284 284" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <mask id="mask0_2166_18178" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="284"
                  height="284">
              <circle cx="142" cy="142" r="142" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2166_18178)">
              <path d="M-43.1995 1071.3L962.38 278.741" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2219 1065.38L962.358 272.816" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2445 1059.45L962.335 266.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.267 1053.53L962.324 260.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.2896 1047.6L962.302 255.042" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3008 1041.69L962.279 249.117" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3234 1035.76L962.256 243.202" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3458 1029.84L962.234 237.277" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.3684 1023.91L962.211 231.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.391 1017.99L962.2 225.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4135 1012.06L962.178 219.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4248 1006.14L962.155 213.578" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4473 1000.21L962.133 207.653" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4698 994.288L962.11 201.729" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.4923 988.373L962.087 195.813" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5149 982.448L962.076 189.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5375 976.523L962.054 183.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5599 970.599L962.031 178.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5712 964.673L962.009 172.114" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.5938 958.749L961.986 166.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6163 952.824L961.963 160.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6388 946.9L961.952 154.34" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6614 940.984L961.93 148.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6838 935.06L961.907 142.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.6952 929.135L961.885 136.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7177 923.21L961.862 130.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7402 917.285L961.839 124.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7627 911.36L961.828 118.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.7853 905.436L961.806 112.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8079 899.511L961.783 106.952" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8191 893.586L961.761 101.027" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8417 887.671L961.738 95.1118" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8641 881.746L961.716 89.187" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.8867 875.821L961.693 83.2622" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9092 869.897L961.682 77.3374" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9318 863.972L961.659 71.4126" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.943 858.047L961.637 65.4878" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.9656 852.122L961.614 59.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-43.988 846.197L961.592 53.6382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0106 840.283L961.569 47.7236" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0332 834.357L961.558 41.7983" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0557 828.433L961.535 35.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.067 822.508L961.513 29.9492" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.0895 816.583L961.49 24.0244" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1121 810.658L961.468 18.0991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1345 804.734L961.445 12.1748" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1571 798.809L961.434 6.25" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1796 792.884L961.411 0.325195" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.1909 786.969L961.389 -5.58984" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2134 781.044L961.366 -11.5146" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.236 775.12L961.344 -17.4395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.2585 769.195L961.321 -23.3643" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.281 763.27L961.31 -29.2891" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3036 757.345L961.287 -35.2139" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3148 751.42L961.265 -41.1387" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3374 745.496L961.242 -47.0635" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3599 739.581L961.22 -52.9785" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.3824 733.656L961.197 -58.9033" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4049 727.731L961.186 -64.8281" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4275 721.806L961.164 -70.7529" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4387 715.881L961.141 -76.6777" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4613 709.957L961.118 -82.6025" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.4839 704.032L961.096 -88.5273" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5063 698.107L961.073 -94.4521" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5289 692.192L961.062 -100.377" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5514 686.267L961.04 -106.292" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5627 680.342L961.017 -112.217" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.5852 674.418L960.995 -118.142" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6078 668.493L960.972 -124.066" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6302 662.568L960.95 -129.991" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6528 656.643L960.938 -135.916" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6754 650.718L960.916 -141.841" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.6979 644.793L960.893 -147.766" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7091 638.878L960.871 -153.681" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7317 632.954L960.848 -159.605" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7543 627.029L960.826 -165.53" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7767 621.104L960.814 -171.455" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.7993 615.179L960.792 -177.38" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8218 609.254L960.769 -183.305" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8331 603.33L960.747 -189.229" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8556 597.405L960.724 -195.154" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.8782 591.49L960.702 -201.069" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9008 585.565L960.69 -206.994" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9232 579.641L960.668 -212.918" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9458 573.716L960.645 -218.843" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.957 567.791L960.623 -224.768" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-44.9796 561.866L960.6 -230.693" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0018 555.941L960.578 -236.618" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0244 550.017L960.555 -242.542" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0471 544.092L960.544 -248.467" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0697 538.177L960.521 -254.382" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.0809 532.252L960.499 -260.307" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1035 526.327L960.476 -266.232" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.126 520.402L960.454 -272.157" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1486 514.478L960.431 -278.082" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1711 508.553L960.42 -284.006" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.1936 502.628L960.397 -289.931" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.205 496.703L960.375 -295.856" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2274 490.788L960.352 -301.771" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.25 484.863L960.33 -307.696" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.2725 478.938L960.307 -313.621" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.295 473.014L960.296 -319.545" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3176 467.089L960.273 -325.47" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3289 461.164L960.251 -331.395" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3513 455.239L960.228 -337.32" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3739 449.315L960.206 -343.245" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.3965 443.399L960.183 -349.169" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4189 437.475L960.172 -355.084" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4415 431.55L960.149 -361.009" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4528 425.625L960.127 -366.934" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4753 419.7L960.104 -372.859" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.4978 413.776L960.082 -378.783" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5204 407.851L960.059 -384.708" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5428 401.926L960.048 -390.633" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5654 396.001L960.026 -396.558" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5767 390.086L960.003 -402.473" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.5992 384.162L959.981 -408.397" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6218 378.237L959.958 -414.322" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6443 372.312L959.935 -420.247" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6669 366.387L959.924 -426.172" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.6893 360.462L959.902 -432.097" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7007 354.538L959.879 -438.021" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7231 348.613L959.857 -443.946" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7457 342.698L959.834 -449.861" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7682 336.773L959.812 -455.786" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.7908 330.848L959.8 -461.711" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8134 324.923L959.778 -467.636" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8358 318.999L959.755 -473.561" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.847 313.074L959.733 -479.485" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8696 307.149L959.71 -485.41" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.8922 301.224L959.688 -491.335" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9147 295.299L959.676 -497.26" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9373 289.384L959.654 -503.175" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9597 283.459L959.631 -509.1" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9711 277.535L959.609 -515.024" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-45.9935 271.61L959.586 -520.949" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0161 265.685L959.564 -526.874" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0387 259.76L959.552 -532.799" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0612 253.835L959.53 -538.724" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.0837 247.911L959.507 -544.648" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.095 241.996L959.485 -550.563" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1176 236.071L959.462 -556.488" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.14 230.146L959.44 -562.413" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1626 224.221L959.417 -568.338" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.1851 218.296L959.406 -574.263" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2076 212.372L959.383 -580.188" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2189 206.447L959.361 -586.112" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2415 200.522L959.338 -592.037" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.264 194.597L959.316 -597.962" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.2865 188.683L959.293 -603.876" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3091 182.758L959.282 -609.801" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3315 176.833L959.26 -615.726" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3429 170.908L959.237 -621.651" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3654 164.984L959.214 -627.576" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.3879 159.059L959.192 -633.5" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4104 153.134L959.169 -639.425" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.433 147.209L959.158 -645.35" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4556 141.294L959.136 -651.265" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4668 135.369L959.113 -657.19" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.4893 129.444L959.09 -663.115" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5118 123.52L959.068 -669.04" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5344 117.595L959.045 -674.964" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5569 111.67L959.034 -680.889" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5795 105.745L959.012 -686.814" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.5907 99.8203L958.989 -692.739" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6133 93.9053L958.967 -698.664" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6357 87.9806L958.944 -704.579" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6583 82.0557L958.921 -710.503" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.6808 76.1308L958.91 -716.428" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7034 70.2062L958.888 -722.353" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7146 64.2813L958.865 -728.278" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7372 58.3564L958.843 -734.203" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7598 52.4316L958.82 -740.127" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.7822 46.5069L958.797 -746.052" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8048 40.5918L958.786 -751.967" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8273 34.667L958.764 -757.892" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8386 28.7422L958.741 -763.817" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8611 22.8174L958.719 -769.742" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.8837 16.8926L958.696 -775.667" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9062 10.9683L958.673 -781.591" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
              <path d="M-46.9287 5.04345L958.662 -787.516" stroke="#A5CF4C" stroke-width="0.75" stroke-miterlimit="10"/>
            </g>
          </g>
        </svg>

        <div class="order1 item mr mb">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19551)">
              <path
                  d="M55.8026 49.3359L65.0819 40.209C69.6026 35.6455 69.6026 28.2788 65.0819 23.7154C60.5396 19.1737 53.207 19.1737 48.6648 23.7154L25.6937 46.8151C21.1731 51.3568 21.1731 58.7452 25.6937 63.287C28.4407 66.0468 32.3774 67.2419 36.2059 66.4596"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path
                  d="M75.7022 57.398C75.7022 61.8746 66.8772 65.5036 55.9757 65.5036C45.0742 65.5036 36.2491 61.8746 36.2491 57.398C36.2491 52.9215 45.0742 49.2925 55.9757 49.2925C66.8772 49.2925 75.7022 52.9215 75.7022 57.398Z"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path
                  d="M36.2491 57.2676V65.4818C36.2491 70.0235 45.3121 73.696 55.9757 73.696C66.6393 73.696 75.7022 70.0235 75.7022 65.4818V57.2676"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M65.1251 50.0312L46.6099 64.5256" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M37.2225 35.2979L51.1739 49.3141" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19551">
                <rect width="56" height="56" fill="white" transform="translate(21 19)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Більш ніж <span>50</span> найменувань лікарських засобів</p>
        </div>

        <div class="order2 item mb">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19552)">
              <path d="M45.3268 70.5335H25.2771V24.2725H66.6535V40.5201" stroke="#005A85" stroke-width="3"
                    stroke-miterlimit="8"/>
              <path
                  d="M50.1582 74.1396C46.7101 70.7035 46.7101 65.125 50.1582 61.6888L67.6537 44.2534C71.1018 40.8172 76.6995 40.8172 80.1475 44.2534C83.5956 47.6896 83.5956 53.2681 80.1475 56.7042L62.652 74.1396C59.2039 77.5758 53.6062 77.5758 50.1582 74.1396Z"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M59.0549 52.9072L71.4636 65.2732" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M65.2699 52.9076L71.5274 46.8201C72.9109 45.4838 75.1457 45.4838 76.5292 46.8201"
                    stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M31.4921 38.0176H57.927" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path d="M31.4921 43.0444H46.6677" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
              <path
                  d="M36.5364 54.2861L37.7922 55.5164H40.325V57.9768L41.5807 59.2071L40.325 60.4373V62.8978H37.7922L36.5364 64.128L35.2806 62.8978H32.7478V60.4373L31.4921 59.2071L32.7478 57.9768V55.5164H35.2806L36.5364 54.2861Z"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19552">
                <rect width="60" height="55" fill="white" transform="translate(24 23)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Майже <span>40</span> рецептурних препаратів</p>
        </div>

        <div class="order3 item mr">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19553)">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M51.8155 34.9821C51.8155 35.9292 51 36.6986 49.9903 36.6986C48.9806 36.6986 48.165 35.9292 48.165 34.9821C48.165 34.0351 48.9806 33.2656 49.9903 33.2656C51 33.2656 51.8155 34.0351 51.8155 34.9821Z"
                    stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path d="M49.9902 62.4268V67.7341" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path
                  d="M53.7185 51.2598C54.1845 51.97 54.4175 52.779 54.4175 53.6274C54.4175 55.482 53.2525 57.1196 51.5243 57.6917L48.6311 58.6783C47.2525 59.132 46.3398 60.4342 46.3398 61.8942C46.3398 63.1767 47.0583 64.3408 48.1845 64.9129L49.9321 65.8205"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path d="M49.9902 43.3281V57.9086" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path
                  d="M52.5922 39.5796H49.0776C46.5534 39.5796 44.4951 41.671 44.4951 44.2359C44.4951 45.5972 45.0971 46.9192 46.1262 47.7873"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path
                  d="M77.3884 43.9396L69.2913 29.7537L58.068 36.3041V23.1836H41.8933V36.3041L30.67 29.7537L22.5923 43.9396L33.8156 50.5097L22.5923 57.0798L30.6894 71.2656L41.9127 64.7153V77.8357H58.0874V64.7153L69.3107 71.2656L77.4078 57.0798L66.1845 50.5097L77.4078 43.9396H77.3884Z"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19553">
                <rect width="58" height="57" fill="white" transform="translate(21 22)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Вже <span>11</span><br>терапевтичних напрямків</p>
        </div>

        <div class="order4 item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="white"/>
            <g clip-path="url(#clip0_2278_19554)">
              <path
                  d="M61.3139 34.4521V76.5273C61.3139 77.1978 60.7755 77.7217 60.1129 77.7217H38.6386C37.9759 77.7217 37.4375 77.1769 37.4375 76.5273V34.4521"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path
                  d="M35.2424 28.3549H38.8042V23.4726C38.8042 22.8021 39.3426 22.2573 39.9846 22.2573H58.9946C59.6573 22.2573 60.175 22.8021 60.175 23.4726V28.3549H63.7368V34.4524H35.2424V28.3549Z"
                  stroke="#005A85" stroke-width="3" stroke-miterlimit="8" stroke-linejoin="round"/>
              <path d="M37.4583 68.2925H52.9479V42.9175H37.4583" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path d="M60.3199 28.229H51.9539" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
              <path d="M38.7007 28.229H42.3867" stroke="#005A85" stroke-width="3" stroke-miterlimit="8"
                    stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_2278_19554">
                <rect width="31" height="58" fill="white" transform="translate(34 21)"/>
              </clipPath>
            </defs>
          </svg>
          <p>Близько <span>15</span> безрецептурних препаратів </p>
        </div>

      </div>

      <h2>Есциталопрам-Тева</h2>

      <picture>
        <source srcset="@/assets/img/brochures/brochuresItem2/main-mob.jpg" media="(max-width: 768px)"/>
        <img src="@/assets/img/brochures/brochuresItem2/main.jpg" alt="">
      </picture>

      <div class="items-wrapper">
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2166_18899)">
              <path
                  d="M61.7925 55.6265H38.2076L30.8626 67.958C30.6941 68.3286 30.593 68.7329 30.593 69.1372C30.593 70.5186 31.6375 71.6979 33.0189 71.8327C33.0863 71.8327 33.1537 71.8327 33.221 71.8664C33.3221 71.8664 33.3895 71.8664 33.4906 71.8664H66.4758C66.4758 71.8664 66.6442 71.8664 66.7453 71.8664C66.8127 71.8664 66.8801 71.8664 66.9475 71.8327C68.3289 71.6979 69.3733 70.5523 69.3733 69.1372C69.3733 68.7329 69.2723 68.3286 69.1038 67.958L61.7588 55.6265H61.7925Z"
                  fill="#00A03B"/>
              <path
                  d="M50 0C22.372 0 0 22.372 0 50C0 77.628 22.372 100 50 100C77.628 100 100 77.628 100 50C100 22.372 77.628 0 50 0ZM67.4528 77.19C67.4528 77.19 67.2844 77.19 67.217 77.19C67.1496 77.19 67.0485 77.19 66.9811 77.19C66.8127 77.19 66.6779 77.2237 66.5094 77.2237H33.5243C33.5243 77.2237 33.221 77.2237 33.0526 77.19C32.9852 77.19 32.8841 77.19 32.8167 77.19C32.7493 77.19 32.6482 77.19 32.5809 77.19C28.403 76.8194 25.2695 73.3491 25.2695 69.1375C25.2695 67.8571 25.5728 66.6105 26.1456 65.4987C26.1456 65.4987 26.1792 65.465 26.1792 65.4313C26.1792 65.4313 26.1792 65.3639 26.2129 65.3639L40.2965 41.6779V28.2008H39.4205C37.938 28.2008 36.7251 26.9879 36.7251 25.5054C36.7251 24.0229 37.938 22.81 39.4205 22.81H60.7143C62.1968 22.81 63.4097 24.0229 63.4097 25.5054C63.4097 26.9879 62.1968 28.2008 60.7143 28.2008H59.8383V41.6779L73.9218 65.3639C73.9218 65.3639 73.9218 65.4313 73.9555 65.4313C73.9555 65.4313 73.9892 65.465 73.9892 65.4987C74.562 66.6105 74.8652 67.8908 74.8652 69.1375C74.8652 73.3154 71.7318 76.7857 67.5539 77.19H67.4528Z"
                  fill="#00A03B"/>
              <path
                  d="M54.7506 43.7667C54.4474 43.2613 54.3463 42.7222 54.38 42.1832C54.38 42.1158 54.38 42.0484 54.38 41.981V28.1333H45.6199V41.981C45.6199 41.981 45.6199 42.1158 45.6199 42.1832C45.6536 42.7222 45.5525 43.2613 45.2493 43.7667L41.4083 50.2357H58.6253L54.7843 43.7667H54.7506Z"
                  fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2166_18899">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>
            <span>Антидепресант.</span>
            Селективний інгібітор зворотного захоплення серотоніну
          </p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2166_18887)">
              <path
                  d="M50 0C22.372 0 0 22.372 0 50C0 77.628 22.372 100 50 100C77.628 100 100 77.628 100 50C100 22.372 77.628 0 50 0ZM63.0054 28.066H61.3881V66.0377C61.3881 72.3383 56.4016 77.2911 50.0337 77.2911C43.6658 77.2911 38.6792 72.3383 38.6792 66.0377V28.066H37.062C35.5795 28.066 34.3666 26.8531 34.3666 25.3706C34.3666 23.8881 35.5795 22.6752 37.062 22.6752H63.0391C64.5216 22.6752 65.7345 23.8881 65.7345 25.3706C65.7345 26.8531 64.5216 28.066 63.0391 28.066H63.0054Z"
                  fill="#00A03B"/>
              <path
                  d="M44.0027 33.4568H49.6631C51.1455 33.4568 52.3585 34.6697 52.3585 36.1522C52.3585 37.6346 51.1455 38.8476 49.6631 38.8476H44.0027V41.3745H49.6631C51.1455 41.3745 52.3585 42.5875 52.3585 44.07C52.3585 45.5524 51.1455 46.7654 49.6631 46.7654H44.0027V49.2923H49.6631C51.1455 49.2923 52.3585 50.5053 52.3585 51.9877C52.3585 53.4702 51.1455 54.6832 49.6631 54.6832H44.0027V66.0713C44.0027 69.4069 46.5633 71.9338 50 71.9338C53.4366 71.9338 55.9973 69.4069 55.9973 66.0713V28.0996H44.0364V33.4904L44.0027 33.4568Z"
                  fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2166_18887">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>Призначається у дозі <span>від 5 мг до 20 мг на добу</span> незалежно від прийому їжі</p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2166_18913)">
              <path
                  d="M75.2359 40.3641C74.0903 40.0272 73.2817 38.949 73.2817 37.7698V31.3682H67.4528V37.7698C67.4528 38.9827 66.6442 40.0609 65.465 40.3641C64.9596 40.4989 62.1968 41.5097 62.1968 45.3169V61.9612H78.5041V45.3169C78.5041 41.5097 75.7749 40.4989 75.2359 40.3304V40.3641ZM74.2588 53.7064H73.0458V54.9194C73.0458 56.4019 71.8329 57.6148 70.3504 57.6148C68.8679 57.6148 67.655 56.4019 67.655 54.9194V53.7064H66.4421C64.9596 53.7064 63.7466 52.4935 63.7466 51.011C63.7466 49.5285 64.9596 48.3156 66.4421 48.3156H67.655V47.1027C67.655 45.6202 68.8679 44.4072 70.3504 44.4072C71.8329 44.4072 73.0458 45.6202 73.0458 47.1027V48.3156H74.2588C75.7413 48.3156 76.9542 49.5285 76.9542 51.011C76.9542 52.4935 75.7413 53.7064 74.2588 53.7064Z"
                  fill="#00A03B"/>
              <path d="M56.806 46.395H36.2871V61.9611H56.806V46.395Z" fill="#00A03B"/>
              <path d="M30.8962 39.7236H22.2372V61.9608H30.8962V39.7236Z" fill="#00A03B"/>
              <path
                  d="M50 0C22.372 0 0 22.372 0 50C0 77.628 22.372 100 50 100C77.628 100 100 77.628 100 50C100 22.372 77.628 0 50 0ZM87.8706 67.3518H12.1294C10.6469 67.3518 9.43396 66.1388 9.43396 64.6563C9.43396 63.1739 10.6469 61.9609 12.1294 61.9609H16.8801V37.0283C16.8801 35.5458 18.093 34.3329 19.5755 34.3329H33.5916C35.0741 34.3329 36.2871 35.5458 36.2871 37.0283V41.6779H57.3787C58.3221 38.814 60.2763 36.9946 62.062 35.9838V31.3342C60.6132 31.3005 59.434 30.1213 59.434 28.6388C59.434 27.1563 60.6469 25.9434 62.1294 25.9434H78.5377C80.0202 25.9434 81.2332 27.1563 81.2332 28.6388C81.2332 30.1213 80.0876 31.2668 78.6388 31.3342V35.9838C81.0647 37.3652 83.8949 40.2965 83.8949 45.3167V61.9609H87.8706C89.3531 61.9609 90.566 63.1739 90.566 64.6563C90.566 66.1388 89.3531 67.3518 87.8706 67.3518Z"
                  fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2166_18913">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>Есциталопрам-Тева має два дозування: <span>10 мг і 20 мг №28</span></p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2166_18925)">
              <path
                  d="M50 27.2207C43.9435 27.2207 39.031 32.1332 39.031 38.1898C39.031 44.2463 43.9435 49.1588 50 49.1588C56.0565 49.1588 60.969 44.2463 60.969 38.1898C60.969 32.1332 56.0565 27.2207 50 27.2207ZM50 54.5088C49.4953 54.5088 49.0242 54.5087 48.5532 54.4415L47.4428 63.1898L50 66.1171L52.5572 63.1898L51.4468 54.4415C50.9758 54.4751 50.4711 54.5088 50 54.5088ZM50 0C22.3755 0 0 22.3755 0 50C0 77.6245 22.3755 100 50 100C77.6245 100 100 77.6245 100 50C100 22.3755 77.6245 0 50 0ZM72.5101 72.6447C71.0633 72.6447 69.852 71.467 69.852 69.9865C69.852 62.0794 65.2086 55.2826 58.5128 52.0525C57.9408 52.4226 57.3351 52.7254 56.6958 53.0283L58.0417 63.6945C58.1427 64.4684 57.9071 65.2086 57.4024 65.8143L52.0188 71.9717C52.0188 71.9717 51.9179 72.039 51.8843 72.0727C51.8506 72.1063 51.817 72.1736 51.7833 72.2409C51.6487 72.3755 51.4805 72.4428 51.3122 72.5101C51.2113 72.5774 51.1104 72.6447 51.0094 72.6783C50.6729 72.8129 50.3365 72.8802 50 72.8802C49.6635 72.8802 49.3271 72.8129 48.9906 72.6783C48.8896 72.6447 48.7887 72.5774 48.6878 72.5101C48.5195 72.4091 48.3513 72.3419 48.2167 72.2409C48.183 72.2073 48.1494 72.14 48.1157 72.0727C48.0821 72.039 48.0148 72.0054 47.9812 71.9717L42.5976 65.8143C42.0929 65.2423 41.8573 64.4684 41.9583 63.6945L43.3042 53.0283C42.6649 52.7591 42.0592 52.4226 41.4872 52.0525C34.7914 55.249 30.148 62.0794 30.148 69.9865C30.148 71.4334 28.9704 72.6447 27.4899 72.6447C26.0094 72.6447 24.8318 71.467 24.8318 69.9865C24.8318 60.7672 29.8116 52.6918 37.214 48.3176C34.9933 45.5249 33.6474 41.9919 33.6474 38.1561C33.6474 29.1386 40.9825 21.8035 50 21.8035C59.0175 21.8035 66.3526 29.1386 66.3526 38.1561C66.3526 41.9919 65.0067 45.5249 62.786 48.3176C70.1884 52.6918 75.1682 60.7672 75.1682 69.9865C75.1682 71.4334 73.9906 72.6447 72.5101 72.6447Z"
                  fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2166_18925">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p>Застосовується у пацієнтів <span>віком від 18 років</span></p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2166_18931)">
              <path
                  d="M61.9609 51.2129C61.0849 50.7412 60.5458 49.8315 60.5458 48.8544V44.5081L50.2358 51.1455C49.3935 51.6846 48.3491 51.7183 47.5067 51.2466C46.6307 50.7749 46.0916 49.8652 46.0916 48.8881V44.9124L37.7358 51.0445C36.9946 51.5836 36.0175 51.7183 35.1415 51.3814C34.2655 51.0445 33.659 50.2695 33.4906 49.3598L29.0768 25.1011H28.3019L25 51.8531V71.1253H75V44.5081L64.69 51.1455C63.8477 51.6846 62.8032 51.7183 61.9609 51.2466V51.2129ZM46.0243 64.9933C46.0243 66.4757 44.8113 67.6887 43.3288 67.6887C41.8464 67.6887 40.6334 66.4757 40.6334 64.9933V56.7385C40.6334 55.2561 41.8464 54.0431 43.3288 54.0431C44.8113 54.0431 46.0243 55.2561 46.0243 56.7385V64.9933ZM59.434 64.9933C59.434 66.4757 58.221 67.6887 56.7385 67.6887C55.2561 67.6887 54.0431 66.4757 54.0431 64.9933V56.7385C54.0431 55.2561 55.2561 54.0431 56.7385 54.0431C58.221 54.0431 59.434 55.2561 59.434 56.7385V64.9933Z"
                  fill="#00A03B"/>
              <path
                  d="M50 0C22.372 0 0 22.372 0 50C0 77.628 22.372 100 50 100C77.628 100 100 77.628 100 50C100 22.372 77.628 0 50 0ZM80.3571 73.8208C80.3571 75.3032 79.1442 76.5162 77.6617 76.5162H22.3046C20.8221 76.5162 19.6092 75.3032 19.6092 73.8208V51.6846C19.6092 51.6846 19.6092 51.4488 19.6092 51.3477L23.2143 22.0687C23.3827 20.721 24.5283 19.7102 25.876 19.7102H31.2668C32.5809 19.7102 33.6927 20.6536 33.9286 21.934L37.9717 44.1712L47.1361 37.4326C47.9447 36.8261 49.0566 36.7588 49.9326 37.1968C50.8423 37.6685 51.4151 38.5782 51.4151 39.5889V43.9353L61.7251 37.2978C62.5674 36.7588 63.6119 36.7251 64.4542 37.1968C65.3302 37.6685 65.8693 38.5782 65.8693 39.5553V43.9016L76.1792 37.2642C77.0216 36.7251 78.066 36.6914 78.9084 37.1631C79.7844 37.6348 80.3234 38.5445 80.3234 39.5216V73.7871L80.3571 73.8208Z"
                  fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2166_18931">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p><span>Виробляється в ЄС</span> (Польща)</p>
        </div>
        <div class="item">
          <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2166_18939)">
              <path
                  d="M49.9832 0C22.383 0 0 22.383 0 49.9832C0 77.5833 22.383 99.9663 49.9832 99.9663C77.5833 99.9663 99.9663 77.5833 99.9663 49.9832C99.9663 22.383 77.617 0 49.9832 0ZM77.1794 50.9929C76.1696 59.273 70.5486 66.2067 62.4706 69.9091V76.9101C62.4706 78.3911 61.2588 79.6028 59.7779 79.6028C58.2969 79.6028 57.0852 78.3911 57.0852 76.9101V71.7604C54.5271 72.3999 51.7671 72.7701 48.9398 72.7701C46.1124 72.7701 43.3861 72.3999 40.7943 71.7604V76.9438C40.7943 78.4248 39.5826 79.6365 38.1017 79.6365C36.6207 79.6365 35.409 78.4248 35.409 76.9438V69.8418C30.4611 67.4857 26.3211 63.8506 23.6284 59.2057C22.9216 59.2057 21.9791 59.2057 20.4981 59.2057C19.926 59.2057 19.0172 59.0374 18.0747 58.2632C16.0552 56.513 15.1128 52.5076 15.1128 45.6412C15.1128 44.1602 16.3245 42.9485 17.8055 42.9485H21.1377C22.6523 37.6977 25.2777 33.9616 29.5523 30.865C29.0475 28.5089 28.6772 27.8021 27.6001 27.2299C26.6914 26.7587 26.1192 25.8162 26.1192 24.8065C26.1192 23.763 26.725 22.8543 27.6338 22.4167C30.2255 21.1713 33.6587 19.8586 37.2602 20.7001C39.7846 21.2723 41.9724 22.7196 43.4197 24.7391C45.2036 24.2679 47.2232 23.8977 48.9061 23.8977C63.2447 23.8977 75.4965 33.625 77.1121 45.6749C78.694 45.5402 79.4345 44.6988 79.4345 42.9148C79.4345 41.4339 80.6462 40.2221 82.1272 40.2221C83.6082 40.2221 84.8199 41.4339 84.8199 42.9148C84.8199 47.6607 81.757 50.8583 77.1457 51.0603L77.1794 50.9929Z"
                  fill="#00A03B"/>
              <path
                  d="M48.9398 29.2157C47.4925 29.2157 45.1027 29.7206 43.0495 30.4274C41.6695 30.8986 40.1549 30.2254 39.65 28.8454C39.0778 27.3981 37.7315 26.2537 36.0822 25.8835C35.2744 25.7152 34.4329 25.7488 33.5914 25.8835C34.3319 27.3645 34.7022 29.0474 35.0051 30.663L35.1734 31.4708C35.409 32.5816 34.9378 33.6923 33.9617 34.2982C29.3504 37.1928 27.0616 40.4914 25.8163 46.146C25.547 47.3914 24.4363 48.2665 23.1909 48.2665H20.5319C20.7002 51.5987 21.1377 53.1134 21.407 53.7865C24.7392 53.7865 25.2441 53.7865 25.2441 53.7865C26.2538 53.7865 27.1626 54.3924 27.6002 55.2675C31.3026 62.7398 39.4481 67.351 48.9061 67.351C52.3057 67.351 55.5033 66.7451 58.3642 65.7017C58.6335 65.5334 58.9028 65.4325 59.2394 65.3651C66.8462 62.2685 71.9624 55.8734 71.9624 48.3002C71.9624 37.9333 61.4272 29.182 48.9398 29.182V29.2157ZM32.548 47.9299C30.6631 47.9299 29.1148 46.4153 29.1148 44.5304C29.1148 42.6455 30.6295 41.1309 32.548 41.1309C34.4666 41.1309 35.9475 42.6455 35.9475 44.5304C35.9475 46.4153 34.4329 47.9299 32.548 47.9299ZM63.1774 40.6933C62.6389 41.4001 61.8311 41.804 60.9896 41.804C60.4511 41.804 59.8789 41.6358 59.4077 41.2992C55.8398 38.6738 51.7335 37.4621 47.8627 37.8996C46.3817 38.0679 45.0691 36.9909 44.9008 35.5099C44.7325 34.0289 45.8095 32.7162 47.2905 32.5479C52.5076 31.9757 57.9267 33.524 62.6052 36.9572C63.817 37.8323 64.0526 39.5153 63.1774 40.727V40.6933Z"
                  fill="#00A03B"/>
            </g>
            <defs>
              <clipPath id="clip0_2166_18939">
                <rect width="100" height="100" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p><span>Доступніший в середньому на 60%</span> за есциталопрам з відповідним дозуванням і формою випуску,
            який
            виробляється в Данії*
          </p>
        </div>
      </div>

      <div class="notes">
        <h3>Посилання</h3>
        <p>*Мається на увазі, що середньозважена роздрібна ціна упаковки цього лікарського засобу в Україні (окрім
          тимчасово окупованих територій) у період 05.2023 на 60% менше ціни упаковки за есциталопрам, який виробляється
          в Данії. За інформацією з Бази даних «Pharmxplorer» © ТОВ «Проксіма Рісерч», 2009.</p>
        <p>Інформація про лікарський засіб РП&nbsp;№UA/11732/01/02, №UA/11732/01/04. Найбільш частими побічними реакціями є:
          збільшення маси тіла, зниження або посилення апетиту, тривога, неспокій, аномальні сни, зниження лібідо у
          чоловіків і жінок, аноргазмія у жінок, невідома: манія, суїцидальні думки, суїцидальна поведінка, головний
          біль, безсоння, сонливість, запаморочення, парестезія, тремор, синусити, позіхання, артралгія, міалгія,
          чоловіки: розлади еякуляції, імпотенція, посилене потовиділення, втома, пірексія. Повний перелік побічних
          реакцій міститься в інструкції для медичного застосування лікарського засобу.</p>
      </div>

      <p class="promo">CBG-UA-01123. Термін придатності матеріалу – 28.06.2025</p>

      <RouterLink to="/brochures" class="back-link">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M8.425 11.9998C8.425 11.8665 8.44566 11.7415 8.487 11.6248C8.52833 11.5081 8.59933 11.3998 8.7 11.2998L12.3 7.6998C12.4833 7.51647 12.7167 7.4248 13 7.4248C13.2833 7.4248 13.5167 7.51647 13.7 7.6998C13.8833 7.88314 13.979 8.1208 13.987 8.4128C13.995 8.7048 13.9077 8.94214 13.725 9.1248L11.85 10.9998L19 10.9998C19.2833 10.9998 19.521 11.0958 19.713 11.2878C19.905 11.4798 20.0007 11.7171 20 11.9998C20 12.2831 19.904 12.5208 19.712 12.7128C19.52 12.9048 19.2827 13.0005 19 12.9998L11.85 12.9998L13.725 14.8748C13.9083 15.0581 13.996 15.2958 13.988 15.5878C13.98 15.8798 13.884 16.1171 13.7 16.2998C13.5167 16.4831 13.2833 16.5748 13 16.5748C12.7167 16.5748 12.4833 16.4831 12.3 16.2998L8.7 12.6998C8.6 12.5998 8.529 12.4915 8.487 12.3748C8.445 12.2581 8.42433 12.1331 8.425 11.9998Z"
              fill="#35786E"/>
        </svg>
        Повернутися
      </RouterLink>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import brochuresItem1Text from "@/store/brochuresItem1/text-data"

export default {
  name: "brochuresItem2",

  data: () => ({}),
}
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.brochuresItem2 {
  position: relative;

  max-width: 820px;
  width: 100%;
  margin: 0 auto;

  padding: 40px 0;

  @media screen and (max-width: 768px) {
    padding: 29px 0;
  }

  sup {
    font-size: 60%;
    vertical-align: text-top;
  }

  ::v-deep sup {
    font-size: 60%;

    vertical-align: text-top;
  }

  .nav-section {
    padding: 0 20px;

    h1 {
      font-size: 48px;
      line-height: 120%;

      text-align: center;

      color: $grey;
      font-family: $EB;

      @media screen and (max-width: 660px) {
        font-size: 28px;
      }
    }

    &__download {
      font-size: 16px;
      line-height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: $teal;
      font-family: $R;
      text-decoration: navajowhite;
      margin: 27px 0;

      svg {
        display: block;
      }
    }
  }

  .content {

    .green-block {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.30);
      background: #00A03B;
      padding: 54px 44px;
      overflow: hidden;

      @media screen and (max-width: 860px) {
        padding: 30px 20px;
        flex-direction: column;
        align-items: center;
      }

      .circle {
        position: absolute;
        z-index: 1;

        &.circle1 {
          left: -140px;
          top: -140px;


        }

        &.circle2 {
          right: -60px;
          top: -110px;
          @media screen and (max-width: 860px) {
            right: -100px;
            top: initial;
            bottom: -170px;
          }
        }

        &.circle3 {
          left: 34%;
          bottom: -180px;

          @media screen and (max-width: 860px) {
            display: none;
          }
        }
      }

      .item {
        display: flex;
        align-items: center;
        max-width: 50%;
        width: 100%;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 860px) {
          max-width: 320px;
          margin-bottom: 20px !important;
          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-size: 24px;
          color: white;
          line-height: 120%;
          font-family: $SB;
          @media screen and (max-width: 860px) {
            font-size: 20px;
          }

          span {
            font-family: $EB;
            font-size: 32px;
            @media screen and (max-width: 860px) {
              font-size: 28px;
            }
          }
        }

        &.mr {
          margin-right: 20px;
          max-width: calc(50% - 20px);
          @media screen and (max-width: 860px) {
            max-width: 320px;
            margin-right: 0;
          }
        }

        &.mb {
          margin-bottom: 50px;
        }

        svg {
          max-width: 100px;
          min-width: 100px;
          width: 100%;
          height: auto;
          display: block;
          margin-right: 20px;
          @media screen and (max-width: 860px) {
            max-width: 74px;
            min-width: 74px;
          }
        }

        &.order1 {
          @media screen and (max-width: 860px) {
            order: 1;
          }
        }

        &.order2 {
          @media screen and (max-width: 860px) {
            order: 3;
          }
        }

        &.order3 {
          @media screen and (max-width: 860px) {
            order: 2;
          }
        }

        &.order4 {
          @media screen and (max-width: 860px) {
            order: 4;
          }
        }
      }
    }

    h2 {
      margin-top: 50px;
      margin-bottom: 24px;
      font-size: 36px;
      font-family: $R;
      color: $green;
      text-align: center;
      padding: 0 20px;

      @media screen and (max-width: 860px) {
        margin-top: 40px;
        font-family: $B;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    picture {
      width: 100%;
      height: auto;
      display: block;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .items-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      @media screen and (max-width: 860px) {
        margin-top: 0;
      }

      .item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E0E0E0;
        padding: 20px;

        p {
          font-size: 24px;
          color: $grey;
          font-family: $SB;
          line-height: 120%;

          @media screen and (max-width: 860px) {
            font-size: 18px;
          }

          span {
            font-family: $EB;
            color: $green;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        svg {
          min-width: 100px;
          width: 100px;
          height: auto;
          display: block;
          margin-right: 20px;

          @media screen and (max-width: 860px) {
            min-width: 66px;
            width: 66px;
          }
        }
      }
    }

    .notes {
      margin-top: 60px;
      background: #EEE;
      padding: 40px 45px;

      @media screen and (max-width: 860px) {
        margin-top: 20px;
      }

      h3 {
        margin-bottom: 25px;
        color: $green;
        font-family: $M;
        font-size: 20px;
      }

      p {
        font-size: 13px;
        color: $grey;
        font-family: $R;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .promo {
      margin-top: 20px;
      font-size: 12px;

      color: $grey;
      font-family: $R;

      @media screen and (max-width: 860px) {
        padding: 0 20px;
      }
    }

    .back-link {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: $teal;
      font-family: $R;
      text-decoration: none;
      margin-top: 33px;
      @media screen and (max-width: 860px) {
        padding: 0 20px;
      }

      svg {
        display: block;
        width: 24px;
        height: auto;
        margin-right: 6px;
      }
    }
  }
}
</style>